@import "./assets/fonts/Mont-Bold.less";
@import "./assets/fonts/Mont-Regular.less";
@import "./assets/fonts/Mont-SemiBold.less";
@import "./tailwind.css";
@import "./theme.less";

html,
body {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#archus-administration-root {
  height: inherit;

  font-family: "montFamily", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16px;

  margin: 0;
  font-family: "montFamily", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .App {
    font-family: "montFamily", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 16px;
    // background: #fafbff;
    height: inherit;
    // padding: 30px;
    display: flex;
    flex-direction: column;
  }

  .ce-ant-modal-title {
    font-weight: 600;
  }

  .ce-ant-input:focus,
  .ce-ant-input-focused {
    border-color: #e5e5e5;
    box-shadow: 0 0 0 0px rgb(29 165 122 / 20%);
    border-right-width: 1px;
    outline: 0;
  }
  .ce-ant-input:hover {
    border-color: #e5e5e5;
    border-right-width: 1px;
  }

  .ce-ant-table-wrapper.enhanced-table {
    .ce-ant-select-single:not(.ce-ant-select-customize-input)
      .ce-ant-select-selector,
    .ce-ant-pagination-options-quick-jumper input,
    .ce-ant-pagination-options-quick-jumper {
      height: 38px !important;
      line-height: 38px !important;
    }
    .ce-ant-table > .ce-ant-table-title {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .ce-ant-pagination.ce-ant-table-pagination {
      align-items: center !important;
      .ce-ant-select-single
        .ce-ant-select-selector
        .ce-ant-select-selection-item,
      .ce-ant-select-single
        .ce-ant-select-selector
        .ce-ant-select-selection-placeholder {
        line-height: 38px !important;
      }
    }
    .ce-ant-table-thead
      > tr
      > th:not(:last-child):not(.ce-ant-table-selection-column):not(
        .ce-ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      width: 0px !important;
    }
    .ce-ant-table-placeholder {
      > td {
        border-bottom: 0px !important;
      }
    }
  }

  .ce-ant-form-item.no-margin {
    margin-bottom: 0 !important;
  }
  .ce-ant-list-bordered {
    border: 1px solid #e8ebf1 !important;
  }

  .ce-ant-table-wrapper.enhanced-table {
    .ce-ant-table > .ce-ant-table-title {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .ce-ant-pagination.ce-ant-table-pagination {
      align-items: center !important;
      .ce-ant-select-single
        .ce-ant-select-selector
        .ce-ant-select-selection-item,
      .ce-ant-select-single
        .ce-ant-select-selector
        .ce-ant-select-selection-placeholder {
        line-height: 38px !important;
      }
    }
    .ce-ant-table-thead
      > tr
      > th:not(:last-child):not(.ce-ant-table-selection-column):not(
        .ce-ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      width: 0px !important;
    }
    .ce-ant-table-placeholder {
      > td {
        border-bottom: 0px !important;
      }
    }
    > .ce-ant-spin-nested-loading {
      height: inherit;
      > .ce-ant-spin-container {
        max-height: calc(100% - 80px) !important;
        height: inherit;
        > .ce-ant-table {
          height: inherit;
          > .ce-ant-table-container {
            height: inherit;
            > .ce-ant-table-body {
              max-height: calc(100% - 55px) !important;
            }
          }
        }
      }
    }
  }
  .ce-ant-dropdown-menu-item-icon {
    font-size: 16px !important;
  }

  .check-trial-in-course-modal {
    .ce-ant-modal-body {
      padding: 0 !important;
      .check-trial-in-course-modal-header {
        border-bottom: 1px solid rgba(184, 187, 193, 0.3);
        line-height: 125%;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .ce-ant-modal-confirm-btns {
      padding: 1rem 1.5rem;
      border-top: 1px solid rgba(184, 187, 193, 0.3);
    }
  }

  .ce-ant-checkbox-group {
    row-gap: 8px;
  }
}

@primary-color: #5B9DFF;@ant-prefix: ce-ant;