@import "./vars.less";

@primary-color: #5b9dff;
@select-dropdown-height: 40px;
@select-background: #f0f1f9;
@border-radius-base: 10px;
@select-single-item-height-lg: 48px;
@input-height-base: 40px;
@input-bg: #f4f6fc;
@input-addon-bg: #f4f6fc;
@menu-bg: transparent;
@layout-body-background: #fafbff;
@text-color: #4f537b;
@text-color-secondary: #a7a9bd;

// buttons
@btn-primary-bg: #f36b7f;
@btn-default-bg: #f0f1f9;
@btn-default-color: #4f537b;
@btn-default-border: #f0f1f9;
@btn-font-weight: 600;

@btn-border-radius-base: 6px;
@btn-border-radius-sm: 4px;

@btn-disable-color: fadeout(#4f537b, 50%);
@btn-disable-bg: fadeout(#f0f1f9, 50%);
@btn-disable-border: transparent;

@btn-default-ghost-color: #f36b7f;
@btn-default-ghost-bg: #fff;
@btn-default-ghost-border: #f36b7f;

@btn-height-base: 38px;
@btn-height-lg: 48px;
@btn-height-sm: 34px;

// modal
@modal-header-padding-vertical: 1rem;
@modal-header-padding-horizontal: 1.5rem;
@modal-body-padding: 0.5rem 1.5rem;
@modal-footer-padding-vertical: 1rem;
@modal-footer-padding-horizontal: 1.5rem;

#archus-administration-root {
  .ce-ant-btn .anticon svg {
    display: block;
  }

  .ce-ant-btn:hover,
  .ce-ant-btn:focus,
  .ce-ant-btn:active {
    color: #4f537b;
    background: #e8ebf1;
    border-color: #e8ebf1;
  }

  .ce-ant-btn-primary:hover,
  .ce-ant-btn-primary:focus,
  .ce-ant-btn-primary:active {
    color: #fff;
    background: #ea455d;
    border-color: #ea455d;
  }

  .ce-ant-btn.ce-ant-btn-primary {
    &[disabled] {
      color: #fff;
      background: fadeout(#f36b7f, 50%);
      border-color: transparent;
    }
  }

  .ce-ant-btn svg {
    display: inline-block;
  }

  .ce-ant-btn.ce-ant-btn-background-ghost {
    color: #f36b7f;
    border-color: #f36b7f;
    &:hover,
    &:focus,
    &:active {
      background: fadeout(#f36b7f, 90%);
      color: #f36b7f;
      border-color: #f36b7f;
    }
    &[disabled] {
      background: transparent;
      color: fadeout(#f36b7f, 50%);
      border-color: fadeout(#f36b7f, 50%);
    }
  }

  .ce-ant-btn.ce-ant-btn-link {
    color: #5b9dff;
    &:hover,
    &:focus,
    &:active {
      color: #5b9dff;
      background: transparent;
      border-color: transparent;
    }
  }

  .ce-ant-btn.ce-ant-btn-text {
    color: #f36b7f;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border-color: transparent;
    }
    &[disabled] {
      color: fadeout(#f36b7f, 50%);
    }
  }

  // date-picker
  @picker-basic-cell-hover-color: #f0f1f9;
  @picker-text-height: 46px;

  .ce-ant-picker-cell .ce-ant-picker-cell-inner {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }

  .ce-ant-picker-month-panel,
  .ce-ant-picker-year-panel {
    .ce-ant-picker-cell .ce-ant-picker-cell-inner {
      padding: 10px 20px;
      width: auto;
      height: auto;
      border-radius: 8.85px;
    }
  }

  .ce-ant-picker-time-panel-column
    > li.ce-ant-picker-time-panel-cell-selected
    .ce-ant-picker-time-panel-cell-inner {
    background: #5b9dff;
    color: #fff;
    &:hover {
      background: #f0f1f9;
      color: #4f537b;
    }
  }

  .ce-ant-picker-time-panel-column:not(:first-child) {
    border-left: 1px solid fadeout(#b8bbc1, 70%);
  }

  .ce-ant-picker-now .ce-ant-picker-now-btn {
    color: #5b9dff;
  }

  .ce-ant-picker-ok .ce-ant-btn {
    background: #5b9dff;
    border-color: #5b9dff;
    padding: 10px 20px;
    height: auto;
    line-height: 1;
    &:hover {
      background: #5b9dff;
      border-color: #5b9dff;
    }
  }

  // from frontend-main - App.scss

  // to use all select option vertical center
  .ce-ant-select-item-option {
    display: flex;
    align-items: center;
  }

  // .ce-ant-input-affix-wrapper {
  //   height: auto;
  //   .ce-ant-input {
  //     background-color: transparent;
  //     margin-left: pxToRem(10px);
  //     outline: none;
  //     border: none;
  //   }
  // }
  .ce-ant-modal-mask,
  .ce-ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .ce-ant-modal-content {
    height: auto;
  }

  .ce-ant-modal-footer {
    padding: 16px 24px;
  }
  .ce-ant-modal-footer button + button {
    margin-bottom: 0;
    margin-left: 10px;
  }

  // .ce-ant-input-affix-wrapper-lg {
  //   max-height: none;
  // }

  .ce-ant-modal-content {
    border-radius: 10px;
  }

  .ce-ant-steps-item-icon > .ce-ant-steps-icon {
    line-height: 2;
  }

  .ce-ant-select-single.ce-ant-select-customize-input .ce-ant-select-selector {
    height: 3rem;
    width: 100%;
    min-width: 100px;
    border-radius: 10px;
    background-color: #f0f1f9;
    color: @text-color;
    font-weight: 600;
    position: relative;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0 11px;
  }

  .ce-ant-input-search-icon::before {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    border-left: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: "";
  }

  .ce-ant-input-search {
    .ce-ant-input {
      background-color: transparent;
    }
  }

  .ce-ant-picker-large .ce-ant-picker-input > input {
    font-weight: 600;
    font-size: 26px;
    color: #4f537b;
  }

  .ce-ant-picker-calendar .ce-ant-picker-panel {
    border-top: none;
  }

  .ce-ant-radio-button-wrapper {
    &:first-child,
    &:last-child,
    &-disabled {
      border-radius: 10px;
      border: none;
    }

    border: none;
  }

  .ce-ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ffffff;
    width: 0;
  }

  .ce-ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ce-ant-radio-button-wrapper:first-child {
    border: 1px solid #5b9dff;
    border-radius: 10px;
  }

  .ce-ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ce-ant-radio-button-wrapper:last-child {
    border: 1px solid #5b9dff;
    border-radius: 10px;
  }

  .ce-ant-radio-button-wrapper-checked:not(
      .ce-ant-radio-button-wrapper-disabled
    ):hover::before {
    background-color: #ffffff;
  }

  .ce-ant-radio-button-wrapper-checked {
    border: 1px solid #5b9dff;
    border-radius: 10px;
  }

  .ce-ant-picker-suffix {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ce-ant-picker-borderless > .ce-ant-picker-input {
    height: 100%;
    background-color: #f0f1f9;
    border-radius: 10px;
    padding: 0 12px;
  }

  .ce-ant-table-thead > tr > th {
    background-color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    color: rgba(79, 83, 123, 0.5);
  }

  .ce-ant-table-ping-left .ce-ant-table-cell-fix-left-first::after,
  .ce-ant-table-ping-left .ce-ant-table-cell-fix-left-last::after {
    box-shadow: none;
  }

  .ce-ant-table-ping-right:not(.ce-ant-table-has-fix-right)
    .ce-ant-table-container::after {
    box-shadow: none;
  }

  .ce-ant-form-item-no-colon {
    font-weight: 600;
    // font-size: 16px !important;
    line-height: 19px;
    color: #4f537b !important;
  }

  .ce-ant-form-item-label {
    white-space: normal;
  }
  .ce-ant-col-24.ce-ant-form-item-label {
    padding: 0px 0px 24px;
    height: max-content;
    line-height: normal;
    > label {
      height: inherit;
    }
  }

  .ce-ant-select-single:not(.ce-ant-select-customize-input)
    .ce-ant-select-selector {
    border: none;
  }

  .ce-ant-timeline-item-head-custom {
    padding: 0;
  }

  .ce-ant-collapse > .ce-ant-collapse-item:last-child,
  .ce-ant-collapse
    > .ce-ant-collapse-item:last-child
    > .ce-ant-collapse-header {
    border-radius: 0;
  }

  .ce-ant-collapse {
    border-radius: 0;
  }
  .ce-ant-tabs-top > .ce-ant-tabs-nav,
  .ce-ant-tabs-bottom > .ce-ant-tabs-nav,
  .ce-ant-tabs-top > div > .ce-ant-tabs-nav,
  .ce-ant-tabs-bottom > div > .ce-ant-tabs-nav {
    margin: 0;
  }

  .ce-ant-tabs-large > .ce-ant-tabs-nav .ce-ant-tabs-tab {
    color: #94959a;
  }

  .ce-ant-picker-input > input {
    cursor: pointer;
  }

  .ce-ant-checkbox-checked::after {
    border: none;
  }

  .ce-ant-tabs-top > .ce-ant-tabs-nav,
  .ce-ant-tabs-bottom > .ce-ant-tabs-nav,
  .ce-ant-tabs-top > div > .ce-ant-tabs-nav,
  .ce-ant-tabs-bottom > div > .ce-ant-tabs-nav {
    margin: 0;
  }

  .ce-ant-input-group-addon .ce-ant-input-search-button,
  .ce-ant-input-search-enter-button
    input
    + .ce-ant-input-group-addon
    .ce-ant-input-search-button {
    background: #f4f6fc;
    box-shadow: none;
    border: none;
  }

  .ce-ant-input-group-addon .ce-ant-btn-primary {
    border-color: #d9d9d9;
  }

  // .ce-ant-input-affix-wrapper:focus,
  // .ce-ant-input-affix-wrapper-focused {
  //   border-color: #d9d9d9;
  //   box-shadow: none;
  // }

  // .ce-ant-input-affix-wrapper:hover {
  //   border-color: #d9d9d9;
  // }

  .ce-ant-popover-placement-bottom
    > .ce-ant-popover-content
    > .ce-ant-popover-arrow,
  .ce-ant-popover-placement-bottomLeft
    > .ce-ant-popover-content
    > .ce-ant-popover-arrow,
  .ce-ant-popover-placement-bottomRight
    > .ce-ant-popover-content
    > .ce-ant-popover-arrow {
    top: 6px;
    border-top-color: #000;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #000;
  }

  .ce-ant-popover-inner-content {
    border-radius: 10px;
    padding: 8px;
    font-size: 12px;
    background: #000;
  }

  .ce-ant-tabs-large > .ce-ant-tabs-nav .ce-ant-tabs-tab {
    color: #94959a;
  }

  .ce-ant-picker-input > input {
    cursor: pointer;
  }
  .ce-ant-tabs-top > .ce-ant-tabs-nav,
  .ce-ant-tabs-bottom > .ce-ant-tabs-nav,
  .ce-ant-tabs-top > div > .ce-ant-tabs-nav,
  .ce-ant-tabs-bottom > div > .ce-ant-tabs-nav {
    margin: 0;
  }

  .ce-ant-tabs-large > .ce-ant-tabs-nav .ce-ant-tabs-tab {
    color: #94959a;
  }

  [ant-click-animating-without-extra-node="true"]::after,
  .ce-ant-click-animating-node {
    display: none;
  }

  .ce-ant-input-search-enter-button + .ce-ant-input-group-addon,
  .ce-ant-input-search-enter-button input + .ce-ant-input-group-addon {
    border: 1px solid #d9d9d9;
  }

  .ce-ant-picker-input > input {
    cursor: pointer;
  }

  .ce-ant-input-group-addon .ce-ant-input-search-button,
  .ce-ant-input-search-enter-button
    input
    + .ce-ant-input-group-addon
    .ce-ant-input-search-button {
    background: #f4f6fc;
    box-shadow: none;
    border: none;
  }

  .ce-ant-input-group-addon .ce-ant-btn-primary {
    border-color: #d9d9d9;
  }

  // .ce-ant-input-affix-wrapper:focus,
  // .ce-ant-input-affix-wrapper-focused {
  //   border-color: #d9d9d9;
  //   box-shadow: none;
  // }

  // .ce-ant-input-affix-wrapper:hover {
  //   border-color: #d9d9d9;
  // }

  .ce-ant-checkbox-inner {
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 0 0 2px @inactive;
    border: 2px solid @inactive;
    background-color: @inactive;
  }
  .ce-ant-checkbox-checked .ce-ant-checkbox-inner {
    background-color: @primary;
    border-color: @primary;
  }

  .ce-ant-checkbox-wrapper + .ce-ant-checkbox-wrapper {
    margin-left: 0px;
  }

  [ant-click-animating-without-extra-node="true"]::after,
  .ce-ant-click-animating-node {
    display: none;
  }

  .ce-ant-radio-wrapper {
    .ce-ant-radio-inner {
      background-color: @inactive;
    }

    .ce-ant-radio + span {
      width: 100%;
    }
    span {
      &:last-child {
        padding-left: 10px;
        color: @text-color;
      }
    }

    .ce-ant-radio:hover {
      .ce-ant-radio-input,
      .ce-ant-radio-inner {
        border-color: transparent;
      }
    }

    .ce-ant-radio-checked {
      border-color: transparent;

      .ce-ant-radio-inner {
        background-color: @primary;
      }

      .ce-ant-radio-inner::after {
        background-color: @bg-color;
      }
    }
  }

  [ant-click-animating-without-extra-node="true"]::after,
  .ce-ant-click-animating-node {
    display: none;
  }

  .ce-ant-input-search-enter-button + .ce-ant-input-group-addon,
  .ce-ant-input-search-enter-button input + .ce-ant-input-group-addon {
    border: 1px solid #d9d9d9;
  }

  .ce-ant-checkbox-wrapper {
    .ce-ant-checkbox {
      border-radius: 2px;
      box-sizing: border-box;
      box-shadow: 0 0 0 1px @inactive;
      border: 2px solid @inactive;
    }

    .ce-ant-checkbox-indeterminate {
      border-radius: 2px;
      box-shadow: 0 0 0 1px @primary;
      border: 2px solid @primary;
      background-color: @primary;

      .ce-ant-checkbox-inner {
        border-color: transparent;
        box-shadow: 0 0 0 2px @primary;
      }

      .ce-ant-checkbox-input {
        border: 1px solid @primary;
      }

      .ce-ant-checkbox-inner::after {
        background-color: transparent;
      }
    }

    .ce-ant-checkbox-checked {
      border-radius: 2px;
      box-shadow: 0 0 0 1px @primary;
      border: 2px solid @primary;

      .ce-ant-checkbox-inner {
        border-radius: 2px;
        box-shadow: 0 0 0 1px @primary;
        border: 2px solid @primary;
      }
    }

    .ce-ant-checkbox-input:focus + .ce-ant-checkbox-inner {
      border-color: transparent;
    }

    .ce-ant-checkbox:hover {
      opacity: 1 !important;

      .ce-ant-checkbox-input,
      .ce-ant-checkbox-inner {
        border-color: transparent !important;
        outline: transparent !important;
      }
    }
  }

  .ce-ant-checkbox-wrapper-disabled {
    cursor: not-allowed;

    .ce-ant-checkbox {
      border-radius: 2px;
      box-sizing: border-box;
      border: 2px solid @inactive;
    }

    .ce-ant-checkbox-indeterminate {
      border-radius: 2px;
      border: 2px solid @inactive;
      background-color: @inactive;
      box-shadow: 0 0 0 1px @inactive;

      .ce-ant-checkbox-inner {
        border-color: transparent;
        box-shadow: 0 0 0 2px @inactive;
      }

      .ce-ant-checkbox-input {
        border: 1px solid @inactive;
      }

      .ce-ant-checkbox-inner::after {
        background-color: transparent;
      }
    }

    .ce-ant-checkbox-checked {
      border-radius: 2px;
      box-shadow: 0 0 0 1px @inactive;
      border: 2px solid @inactive;

      .ce-ant-checkbox-inner {
        border-radius: 2px;
        box-shadow: 0 0 0 1px @inactive;
        border: 2px solid @inactive;
      }
    }

    .ce-ant-checkbox-input:focus + .ce-ant-checkbox-inner {
      border-color: transparent;
    }

    .ce-ant-checkbox-input,
    .ce-ant-checkbox-inner {
      outline: transparent !important;
      border-color: transparent !important;
      background-color: @inactive;
    }
  }

  .ce-ant-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  .ce-ant-form-item-explain,
  .inline-error-message {
    margin: 0.7em 0;
  }

  .ce-ant-picker-input > input {
    cursor: pointer;
  }

  .ce-ant-input-search-enter-button + .ce-ant-input-group-addon,
  .ce-ant-input-search-enter-button input + .ce-ant-input-group-addon {
    border: 1px solid #d9d9d9;
  }

  .ce-ant-picker-range-separator {
    padding: 0 1.375rem 0 0.5rem;
  }

  .ce-ant-mentions .rc-textarea,
  .ce-ant-picker {
    background-color: #f4f6fc;
  }

  .ce-ant-popover {
    .ce-ant-popover-inner-content {
      background: #fff;
    }
  }

  .ce-ant-tabs-tab {
    font-size: 1rem;
  }

  .ce-ant-table-column-sorter-full {
    display: flex;
    align-items: flex-start;
  }

  // from frontend-main - Sidebar.scss
  .ce-ant-menu {
    border-right: none;
  }

  .ce-ant-layout-sider {
    border-right: 1px solid #f0f0f0;
  }
  .ce-ant-menu-inline-collapsed-tooltip {
    display: none;
  }
  .ce-ant-menu-inline {
    padding: 0 20px;
    color: @primary-text;
    .active,
    .w-full {
      display: flex;
      align-items: center;
    }
  }

  .ce-ant-menu-inline > .ce-ant-menu-submenu > .ce-ant-menu-submenu-title,
  .ce-ant-menu-inline > .ce-ant-menu-item {
    height: 60px;
    line-height: 60px;
    border-radius: 10px;
  }
  .sideBar {
    height: 100vh;
  }

  .ce-ant-menu-submenu-active {
    color: @bg-color;
    .ce-ant-menu-submenu-title {
      background-color: rgba(79, 83, 123, 0.1);
      .active {
        color: @bg-color;
        font-weight: 700;
      }
    }
    .ce-ant-menu-submenu-arrow {
      &::before,
      &::after {
        background: @bg-color !important;
      }
    }
  }

  .ce-ant-menu-submenu-selected {
    .ce-ant-menu-submenu-title {
      background-color: @secondary;
      color: @bg-color;
      margin: 0 auto;
    }
    .ce-ant-menu-submenu-title {
      .ce-ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: @bg-color !important;
        }
      }
      &:hover {
        .ce-ant-menu-submenu-arrow {
          &::before,
          &::after {
            background: @bg-color !important;
          }
        }
      }
    }
  }
  .ce-ant-menu-submenu-title:hover {
    a {
      color: @primary-text;
    }
    .ce-ant-menu-submenu-arrow {
      &::before,
      &::after {
        background: @primary-text !important;
      }
    }
  }

  .sideBar {
    .ce-ant-menu-light .ce-ant-menu-item:not(.ce-ant-menu-item-selected):hover {
      color: @primary-text;
    }
    .ce-ant-menu.ce-ant-menu-inline-collapsed
      > .ce-ant-menu-submenu
      > .ce-ant-menu-submenu-title,
    .ce-ant-menu.ce-ant-menu-inline-collapsed > .ce-ant-menu-item {
      padding: 0 calc(50% - 32px / 2);
    }
    .ce-ant-menu-inline > .ce-ant-menu-submenu > .ce-ant-menu-submenu-title,
    .ce-ant-menu-inline > .ce-ant-menu-item {
      height: 50px;
      line-height: 50px;
      border-radius: 6px;
    }
    .ce-ant-menu-inline-collapsed
      > .ce-ant-menu-submenu
      > .ce-ant-menu-submenu-title {
      height: 50px;
      line-height: 50px;
      border-radius: 6px;
      width: 60px;
      margin: 8px 0;
    }
    .ce-ant-menu-inline-collapsed > .ce-ant-menu-item {
      height: 50px;
      line-height: 50px;
      border-radius: 6px;
      width: 60px;
      margin: 8px auto;
    }

    .ce-ant-menu:not(.ce-ant-menu-horizontal) .ce-ant-menu-item-selected {
      background-color: @secondary;
      :hover {
        color: @bg-color !important;
      }
      .active {
        color: @bg-color !important;
      }
    }

    .ce-ant-menu-item-active {
      background-color: rgba(79, 83, 123, 0.1);
      a:hover {
        color: @primary-text;
      }
    }

    .ce-ant-menu-inline .ce-ant-menu-item-selected::after {
      display: none;
    }
    .ce-ant-menu:not(.ce-ant-menu-horizontal)
      .ce-ant-menu-vertical
      .ce-ant-menu-item-selected {
      border-radius: 0;
      background: rgba(79, 83, 123, 0.1);
      a {
        color: @bg-color;
      }
    }
    .ce-ant-menu-item-selected {
      :hover {
        color: @bg-color !important;
      }
      color: @bg-color !important;
      a {
        color: @bg-color !important;
      }
      a:hover {
        color: @bg-color !important;
      }
    }

    .ce-ant-menu-sub.ce-ant-menu-inline > .ce-ant-menu-item {
      height: 48px;
      line-height: 48px;
      border-radius: 10px;
    }

    .ce-ant-menu-submenu-open {
      .ce-ant-menu:not(.ce-ant-menu-horizontal) .ce-ant-menu-item-selected {
        width: 100%;
        height: 48px;
        line-height: 48px;
        background: rgba(79, 83, 123, 0.1);
        border-radius: 10px;
        font-weight: bold;
        .active {
          color: @bg-color;
        }
      }
    }

    .ce-ant-menu-inline-collapsed
      > .ce-ant-menu-submenu
      > .ce-ant-menu-submenu-title {
      height: 60px;
      line-height: 60px;
      border-radius: 10px;
      width: 60px;
      margin: 8px 0;
      .active,
      .w-full {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ce-ant-menu-inline-collapsed > .ce-ant-menu-item {
      height: 60px;
      line-height: 60px;
      border-radius: 10px;
      width: 60px;
      margin: 8px auto;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ce-ant-menu-vertical {
      .ce-ant-menu-submenu {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // ProfilePage.scss
  .ce-ant-tabs-content-top {
    height: 100%;
  }

  .ce-ant-tabs-content-holder {
    overflow: auto;
  }

  .ce-ant-drawer-footer {
    padding: 16px 24px;
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
